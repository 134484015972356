@import '../../../utils/variables';

.trustpilot {
  position: absolute;
  padding: 8px;
  width: 100%;
  bottom: 24px;
}

@media (min-width: 1281px) {
  .trustpilot {
    position: absolute;
    padding: 8px;
    width: 30%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.sb-BoardsStrip::-webkit-scrollbar {
  display: none;
}

.sb-BoardsStrip {
  position: relative;
  // min-height: 380px;
  // height: calc(100% - 54px);
  scrollbar-width: none;

  .boardsWrapper {
    position: relative;
    // min-height: 380px;
    display: flex;
    // justify-content: flex-start;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  &-noBoards {
    overflow: inherit;
    margin-top: 0;
    .boardsWrapper {
      top: 20px;
      min-height: 300px;
      height: unset;
      justify-content: center;
      align-items: center;
    }
    .sb-BoardsStrip-text {
      display: block;
    }
  }

  // &-iOS {
  //   min-height: 360px;
  // }

  &-frames {
    .boardsWrapper {
      top: 0;
    }
    // height: calc(100% - 110px);
    // max-height: calc(100% - 280px);
    .sb-BoardsStrip-text {
      visibility: hidden;
    }
  }

  &-offer {
    .boardsWrapper {
      top: -5px;
    }
  }

  p {
    margin: 0;
    color: #44b1fb;
  }

  *:focus {
    outline: none;
  }

  & .message {
    display: flex;
    align-items: center;
    padding: 15px 35px 10px;
    background-color: #fff;
    border-radius: 5px;
    z-index: 1;

    &-bold {
      font-weight: 600;
    }

    img {
      flex: 0 1 25px;
      max-width: 25px;
      margin-right: 10px;
    }

    p {
      color: $color-pink;
      margin: 0;
    }
  }

  &-text {
    position: relative;
    top: -5px;
    display: none;
    z-index: -1;
    margin: 0 auto;
    text-align: center;
    font-size: 24pt;
    font-weight: 600;
    color: transparent;
    // @extend %text-shadow;

    // svg {
    //   width: 50px;
    //   margin-right: 15px;
    //   filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
    // }
  }

  &-left {
    background: #000;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    img {
      height: 40px;
      padding: 10px;
    }
  }

  &-right {
    padding: 10px;
    display: flex;
    flex-direction: column;
    background: rgb(35, 35, 35);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    p {
      margin: 0;
      font-size: 12px;
      color: rgb(230, 188, 105);
    }

    img {
      margin-top: 10px;
      width: 120px;
    }
  }

  @include mq(phone-only) {
    overflow: auto;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
    flex: 1;
    justify-content: space-evenly;
    // gap: 12px;

    .boardsWrapper {
      padding-left: 12px;
      overflow-x: scroll;
      overflow-y: hidden;
      left: 0;
      // width: 100%;
    }

    .margin {
      margin-left: 12px;
    }

    &-text {
      font-size: 20pt;
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }

  @media screen and (min-height: 600px) {
    .boardsWrapper {
      &-noBoards {
        height: unset;
      }
    }
    &-text {
      visibility: visible !important;
    }
  }

  @media screen and (min-height: 700px) and (max-width: 700px) {
    // min-height: 430px;

    .boardsWrapper {
      // min-height: 430px;
      &-noBoards {
        min-height: 300px;
        align-items: center;
      }
    }
    &-text {
      visibility: visible !important;
    }
  }

  @media screen and (min-height: 800px) and (max-width: 800px) {
    // min-height: 500px;
    .boardsWrapper {
      // min-height: 500px;
      &-noBoards {
        min-height: 300px;
      }
    }
  }

  @media screen and (min-width: 600px) {
    height: calc(100%);
    display: flex;
    overflow: auto;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    &-offer {
      padding-top: 30px;
    }

    &-frames {
      // height: calc(100% - 90px);
      &.sb-BoardsStrip-noBoards {
        // height: calc(100% - 110px);
      }
    }

    .boardsWrapper {
      // flex-wrap: wrap;
      // justify-content: center;
      // width: 100%;
    }
  }

  @media screen and (min-width: 1200px) and (min-height: 701px) {
    &-frames {
      // height: calc(100% - 110px);
    }
  }

  @media screen and (min-width: 750px) and (max-height: 750px) {
    & .message {
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 989px) {
    &-text {
      width: 100%;
      margin-left: 0;
    }
  }

  @include mq(medium-desktop-up) {
    &-text {
      top: 0;
      font-size: 26pt;
    }
  }

  @media only screen and (min-width: 1800px) {
    &-text {
      font-size: 30pt;
    }
  }
}

  .sb-Board::before, .sb-Board::after {
    content: '';  /* Insert pseudo-element */
    margin: auto; /* Make it push flex items to the center */
  }