@import '../../../utils/variables';

.sb-PageWrapper {
	// top: $mobile-header-height;
	box-sizing: border-box; 
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 100%;
	height: calc(100vh - 150px);
	display: flex;
	flex-direction: column;
	flex: 1;

	@include mq(phone-only) {
		flex: 1;
	}
	
	&-home {
		position: relative;
		top: 0;
		box-sizing: border-box;
		background: #fff;
	}

	@media only screen and (min-width: 700px) {
		// top: $desktop-header-height;
		// height: calc(100% - #{$mobile-footer-height} - #{$desktop-header-height});
	}

	@media only screen and (min-width: 990px) {
		// height: calc(100% - 90px - #{$desktop-header-height});
		// top: $desktop-header-height;
		background-color: $color-background;
		&-home {
			top: 0;
		}
	}

	@media only screen and (min-width: 1200px) and (min-height: 701px) {
		&-home {
			// height: calc(100vh - #{$desktop-footer-height} - #{$desktop-header-height});
		}
	}
}
