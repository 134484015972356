@import "./utils/variables";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&display=swap");

@font-face {
	font-family: "Avenir Next";
	src: url("./assets/fonts/Avenir Next Thin.woff");
	font-weight: 300;
}

@font-face {
	font-family: "Avenir Next";
	src: url("./assets/fonts/Avenir Next Heavy.woff");
	font-weight: 900;
}

* {
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
	-webkit-tap-highlight-color: transparent;
}

* {
	font-family: "Montserrat", sans-serif;
	box-sizing: border-box;
}

html {
	&.body-boards {
		overflow-y: hidden;
		@include mq(phone-only) {
			overflow: hidden;
		}
	}
}

body, html {
	height: 100%;
}

body {
	margin: 0;
	padding: 0;
	font-family: "Montserrat", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&::-webkit-scrollbar {
		display: none;
	}

	scrollbar-width: none;

	&.body-boards {
		@include mq(phone-only) {
			// width: 100vw;
			// height: 100vh;
			// overflow-y: scroll;
			// overflow-x: hidden;
			// -webkit-overflow-scrolling: touch;
		}
	}

	a {
		text-decoration: none;
	}

	#root {
		display: flex;
		flex-direction: column;
		height: 100%;
		// position: relative;
		// margin-top: $mobile-header-height;
		// margin-bottom: $mobile-footer-height;
		// overflow-x: hidden;
	}

	@media only screen and (min-width: 700px) {
		#root {
			// margin-top: $desktop-header-height;
			margin-bottom: $desktop-footer-height-medium;
		}
	}

	@media only screen and (min-width: 990px) and (min-height: 700px) {
		#root {
			margin-bottom: $desktop-footer-height-medium;
		}
	}

	@media only screen and (min-width: 1200px) and (min-height: 700px) {
		#root {
			margin-bottom: $desktop-footer-height;
		}
	}
}
