@import '../../../utils/variables';

.sb-BoardMenu-mobile-animation {
	&-enter {
		transform: translateY(100vh);
		transition: all 300ms;
		&#{&}-active {
			transform: translateY(0);
			transition: all 300ms;
		}
	}
	&-exit {
		&#{&}-active {
			transform: translateY(100%);
			opacity: 0;
			transition: all 0ms;
		}
	}
}

.sb-BoardMenu-animation {
	transition: all 300ms;

	&-enter {
		transition: all 300ms;
		opacity: 0;
		&#{&}-active {
			opacity: 1;
		}
	}
	&-exit {
		opacity: 0;
		//transition: all 300ms;
		&#{&}-active {
			opacity: 0;
		}
	}
}

.sb-BoardMenu {
	display: none;
	&-show {
		display: block;
	}

	&-backdrop {
		width: 100vw;
		height: 100vh;
		position: fixed;
		background: rgba(0, 0, 0, 0.4);
		left: 0;
		top: 0;
		z-index: 3;
		@media only screen and (min-width: 990px) {
			display: none;
		}
	}
	&-background {
		position: absolute;
		display: none;
	}

	@media only screen and (max-width: 989px) {
		background-color: $color-background;
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 9999;
		width: 100vw;
		height: 180px;
		// padding: 0 30px;
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		box-shadow: 0 0 5.6px 0 rgba(0, 0, 0, 0.3);
		box-shadow: 0px 5px 1px rgb(35 33 66 / 10%);

		&-overBoard {
			display: none !important;
		}

		button {
			svg {
				position: absolute;
				left: 15px;
				width: 20px;
				align-self: center;
			}
			// margin: 0 0 15px 0 !important;
			max-height: 45px;
			justify-content: center !important;
			font-size: 20px !important;
			border: none; 
		}
	}

	@media only screen and (min-width: 990px) {
		&-dock {
			display: none !important;
		}
	}

	position: absolute;
	z-index: 3;
	width: 70%;
	height: 100%;

	svg {
		margin: 0 10px;
		width: 20px;
		height: 20px;
	}

	> div {
		position: relative;
		// height: 100%;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 8px;

		button {
			path {
				transition: all 0.1s ease-in-out;
			}
			transition: all 0.1s ease-in-out;
			cursor: pointer;
			display: flex;
			justify-content: left;
			align-items: center;
			// margin: 10px 0;
			font-size: 22px;
			font-weight: 600;
			height: 55px;
			border-radius: 10px;
		}
	}

	@media only screen and (max-width: 1680px) {
		> div {
			button {
				font-size: 20px;
			}
		}
	}

	@media only screen and (max-width: 1200px) {
		> div {
			button {
				font-size: 18px;
			}
		}
	}
}

.sb-BoardMenu-edit {
	background: #fff;
	path {
		fill: $color-pink-dark;
	}
	border: 1px solid $color-pink-dark;
	color: $color-pink-dark;
}

.sb-BoardMenu-edit:hover {
	background: $color-pink-dark;
	path {
		fill: #fff;
	}
	color: #fff;
}

.sb-BoardMenu-remove {
	svg {
		width: 16px;
		height: 16px;
	}
	background: #fff;
	path {
		fill: #e41b72;
	}
	border: 1px solid #e41b72;
	color: #e41b72;
}

.sb-BoardMenu-remove:hover {
	color: #fff;
	background: #e41b72;
	path {
		fill: #fff;
	}
}
