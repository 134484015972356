@import '../../../utils/variables';

.sb-Board {
  position: relative;
  width: 255px;
  height: 255px;
  display: inline-flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;

  &-animation {
    transition: all 1000ms;

    &-enter {
      transition: all 1000ms;
      opacity: 0.3;
      &#{&}-active {
        opacity: 1;
      }
    }
    &-exit {
      opacity: 0.3;
      &#{&}-active {
        opacity: 0.3;
      }
    }
  }

  &-backdrop {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 3;
    &-animation {
      &-enter {
        opacity: 0;
        transition: all 300ms;
        &#{&}-active {
          opacity: 1;
          transition: all 300ms;
        }
      }
      &-exit {
        &#{&}-active {
          opacity: 0;
          transition: all 300ms;
        }
      }
    }

    right: 0;
    z-index: 1;
    overflow: hidden;
  }

  &-moneyBack {
    width: 100%;
  }

  @media only screen and (min-width: 990px) {
    &-backdrop {
      display: none;
      top: $desktop-header-height;
      width: 50vw;
      height: 1000vh;
      background-color: unset;
      background-image: linear-gradient(
        to right,
        rgba(154, 153, 153, 0) 0%,
        rgba(0, 0, 0, 0.5) 100%
      );
    }
  }

  @include mq(phone-only) {
    transform: scale(1.05);

    &.appear,
    &-upload-left {
      margin-right: 12px;
    }

    &-upload-left {
      margin-left: 12px;
    }
  }

  &-frame {
    img {
      width: 106%;
      height: 106%;
      position: absolute;
      top: 55.4%;
      left: 48%;
      z-index: 0;
      transform: translate(-50%, -50%);
      display: none;

      &.show {
        display: block;
      }

      &.ever,
      &.bold {
        z-index: 2;
      }
    }
  }

  &-container {
    position: relative;
    top: -1px;
    transform: scale(0.865);
    width: 255px;
    height: 255px;
    z-index: 1;
    overflow: hidden;

    &-ever,
    &-bold {
      transform: scale(0.793);
    }
    &-clean,
    &-edge {
      transform: scale(0.65);
    }

    > div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden !important;
      user-select: none;
    }

    &-upload {
      z-index: 3;
    }

    &_uploading {
      background-color: #fff;
    }

    @media only screen and (min-width: 768px) {
      top: -1px;
    }

    @include mq(phone-only) {
      &-classic,
      &-eco,
      &-metallic,
      &-canvas,
      &-original,
      &-black-foam,
      &-canvas-foam,
      &-metallic-foam,
      &-gray-foam,
      &-frame,
      &-classic-dark,
      &-classic-light,
      &-ever,
      &-bold {
        transform: scale(0.865);
      }
    }

    @include mq(small-desktop-up) {
      top: -1px;
      width: 255px;
      height: 255px;

      &-upload {
        transform: scale(0.861);
      }

      &-classic,
      &-ever,
      &-bold {
      }

      &-classic,
      &-bold {
        // transform: scale(0.946);
      }
    }

    @include mq(medium-desktop-up) {
      &-upload {
        transform: scale(0.946);
      }
      &-eco,
      &-metallic,
      &-canvas,
      &-original,
      &-black-foam,
      &-canvas-foam,
      &-metallic-foam,
      &-gray-foam,
      &-frame,
      &-classic,
      &-classic-dark,
      &-classic-light,
      &-bold {
        transform: scale(0.946);
      }
      &-ever,
      &-bold {
        transform: scale(0.87);
      }
    }

    @include mq(big-desktop-up) {
      &-upload {
        transform: scale(1.286);
      }
      &-eco,
      &-metallic,
      &-canvas,
      &-original,
      &-black-foam,
      &-canvas-foam,
      &-metallic-foam,
      &-gray-foam,
      &-frame,
      &-classic,
      &-classic-dark,
      &-classic-light {
        transform: scale(1.286);
      }
      &-ever,
      &-bold {
        transform: scale(1.286);
      }
      &-clean,
      &-edge {
        transform: scale(1);
      }
    }
  }

  &-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden !important;
    user-select: none;

    &-edge,
    &-clean {
      // transform: scale(0.8);
    }

    &-ever {
      transform: scale(1.089);
    }

    &-eco,
    &-metallic,
    &-canvas,
    &-original,
    &-black-foam,
    &-canvas-foam,
    &-metallic-foam,
    &-gray-foam,
    &-frame,
    &-classic,
    &-classic-dark,
    &-classic-light,
    &-ever,
    &-bold {
      transform: scale(1);
    }
  }

  &-blob {
    position: absolute;
    opacity: 1;
    margin: auto;
    will-change: transform;
    transition: transform 0.1s ease-out, opacity 0.3s 0.3s,
      -webkit-transform 0.1s ease-out;
    bottom: 2px !important;
    left: -9994px !important;
    right: -9994px !important;
    max-width: unset !important;
    max-height: unset !important;
    top: 0;
    z-index: -1;
  }

  &-img {
    width: 100%;
    height: 100%;
  }

  // @media only screen and (min-width: 600px) {
  //   &-upload-left {
  //     display: none;
  //   }
  // }

  @include mq(medium-desktop-up) {
    width: 280px;
    height: 280px;
  }

  @media only screen and (min-width: 1800px) {
    width: 380px;
    height: 380px;
  }
}

.appear {
  @extend %boardFadeInAnimation;
}

.right-initial {
  @extend %boardSlideRightInitial;
}

.right {
  @extend %boardSlideRight;
}

.left-initial {
  @extend %boardSlideLeftInitial;
}

.left {
  @extend %boardSlideLeft;
}

.disappear {
  @extend %boardFadeOutAnimation;
}

.no-animation {
  animation: none;
}

.scroll-fix {
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
}
