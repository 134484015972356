@import '../../../utils/variables';

.sb-Footer {
	&-hidden {
		display: none;
	}

	width: $desktop-width;
	// height: 100px;
	// position: fixed;
	bottom: 0;
	// z-index: 42;
	display: flex;
	justify-content: center;
	background-color: #fff; 
	align-items: center;

	@include mq(phone-only) {
		z-index: 20;
		padding: 10px 0px 10px;
	}

	&-checkout {
		&-grey {
			background-color: #eee;
		}
	}

	> div {
		// flex-basis: 33%;
	}

	&-left {
		display: none;
		position: relative;
		top: -45px;
	}

	&-middle {
		display: none;
		color: $color-pink;
		text-align: center;
		font-weight: 700;
		letter-spacing: 0.3px;

		> div:first-child {
			font-size: 16pt;
		}

		> div:nth-child(2) { 
			color: $color-font;
			margin-bottom: 5px;
			font-weight: 400;
			letter-spacing: 0.5px;
		}
	}

	&-social {
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		padding: 10px 0 10px 20px;

		@include mq(phone-only) {
			display: none;
		}

		span {
			color: $color-light;
			font-size: 10pt;

			@media only screen and (max-width: 1330px) {
				font-size: 8pt;
			}
		}
	}

	&-icons {
		display: flex;

		img {
			height: 20px;
			margin: 0 10px 0 0;
			cursor: pointer;
		}
	}

	&-info {
		text-align: left;
	}

	&-actions {
		// display: flex;
		// justify-content: flex-end;
		// align-items: center;

		@include mq(phone-only) {
			// padding: 0 10px;
		}
	}

	&-button {
		// width: 340px;
		height: 52px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 10px;
		text-decoration: none;
		border: none;
		outline: none;
		font-size: 27px;
		font-weight: 600;
		letter-spacing: 0.1px;
		color: white;
		
		&:hover {
			cursor: pointer; 
		}

		&.btn-paypal {
			height: 46px;		
			iframe {
				border-radius: 10px;
			}
		}

		img.checkout {
			height: 24px;
			margin-right: 10px;
		}

		> *:not(.paypalbuttons-real):not(.sb-ApplePayButton):not(.sb-CheckoutInfo-applepay) {
			display: flex;
    		align-items: center;
    		justify-content: center;
			width: 100%;
			height: 100%;  
			background-image: linear-gradient(96.86deg, #A637FC -1.49%, #F34EF8 169.78%);
			border-radius: 4px;
			// box-shadow: 0px 29px 52px rgb(138 77 186 / 17%); 
		}
		
		&.btn-paypal > * {
			height: 0;
			background-color: white;
		}

		&-place-order {
			display: flex;
			justify-content: center;
			align-items: center; 
			background-image: linear-gradient(96.86deg, #A637FC -1.49%, #F34EF8 169.78%);
			border-radius: 4px;
			&.disabled {
				background-color: #b7bec3;
			}
		}
	}

	&-homeMobile {
		width: 100%;
		font-weight: bold;
		text-align: center;
	}

	&-right {
		padding: 10px 20px 10px 0;
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-end;

		@media only screen and (max-width: 989px) {
			padding-right: 10px;
		}

		@include mq(phone-only) {
			display: none;
		}

		> div {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: flex-end;

			> div {
				display: flex;
				align-items: center;

				> img {
					width: 25px;
					margin-right: 5px;
				}

				> span {
					font-weight: 700;
					font-size: 18px;
					color: $color-primary;

					@media only screen and (max-width: 989px) {
						font-size: 16px;
					}
				}
			}

			> a {
				color: $color-primary;
				margin-left: 5px;

				@media only screen and (max-width: 989px) {
					font-size: 14px;
				}
			}
		}

		> span {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			color: $color-light;
			font-size: 10pt;

			@media only screen and (max-width: 1330px) {
				font-size: 8pt;
			}

			> div {
				display: flex;
				flex-wrap: wrap;
			}

			a {
				color: $color-light;
				text-decoration: none;

				&:first-of-type {
					margin-right: 5px;
				}
			}
		}
	}

	&-completed {
		justify-content: center;
		align-items: center;

		.sb-Footer-button {
			flex-basis: 300px;

			a, a:visited, a:hover, a:focus {
				text-decoration: none;
				color: white;
			}
		}
	}
	
	@media only screen and (min-width: 990px) {
		// height: 90px;
		padding: 10px 0;

		&-mobile {
			display: none;
		}
		
		&-completed {
			justify-content: center;
			
			.sb-Footer-button {
				position: relative;
				left: 40px;
				flex-basis: 33%;
				margin-right: $modal-right-medium;
			}
		}
		&-left {
			display: flex;
			top: -70px;
		}
		&-middle {
			display: block;

			> div {
				position: relative;
				right: -33px;
			}
		}
		&-actions {
			// margin-right: $modal-right-medium;
		}
		&-button {
			height: 55px;
			font-size: 24px;
		}
	}

	@media only screen and (min-width: 1200px) and (min-height: 701px) {
		// height: 200px;
		align-items: center;

		&-button {
			width: $footer-button-width;
		}

		&-completed {
			.sb-Footer-button {
				margin-right: $modal-right-medium;
			}
		}
		

		&-checkout {
			// justify-content: flex-end;
		}

		&-left {
			top: -55px;
			display: flex;
		}

		&-middle {
			display: block;

			> div {
				right: 0;
				font-size: 18px;
			}

			> div:first-child {
				font-size: 22px;
			}
		}

		&-button {
			&-disabled{
				background: #6f6f6e;
			}

			// width: $footer-button-width-medium;
			// height: 70px;
			border-radius: 10px;
			font-size: 30px;

			img.checkout {
				height: 29px;
			}

			> span {
				font-size: 30px;
			}
		}

		&-actions {
			display: flex;
			justify-content: flex-end;
		}
	}

	@media only screen and (min-width: 1440px) and (min-height: 701px) {
		&-actions {
			// margin-right: $modal-right;
		}

		&-button {
			// width: $footer-button-width;
		}
	}
}
