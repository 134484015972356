$mq-phone:   599px;
$mq-tablet-portrait:  600px;
$mq-tablet-landscape:  900px;
$mq-small-desktop-up:   1200px;
$mq-medium-desktop-up:   1440px;
$mq-big-desktop-up:   1800px;

@mixin mq($size) {
  @if      $size == phone-only  { @media only screen and (max-width: $mq-phone) { @content; } }
  @else if $size == tablet-portrait-up { @media only screen and (min-width: $mq-tablet-portrait) { @content; } }
  @else if $size == tablet-landscape-up { @media only screen and (min-width: $mq-tablet-landscape) { @content; } }
  @else if $size == small-desktop-up { @media only screen and (min-width: $mq-small-desktop-up) { @content; } }
  @else if $size == medium-desktop-up  { @media only screen and (min-width: $mq-medium-desktop-up) { @content; } }
  @else if $size == big-desktop-up  { @media only screen and (min-width: $mq-big-desktop-up) { @content; } }
}

// DIMENSIONS

$footer-button-width: 440px;
$footer-button-width-medium: 420px;
$checkout-title-height: 40px;
$checkout-title-font: 20px;
$checkout-close-font: 24px;

$modal-height: 500px;
$modal-height-large: 530px;
$modal-right-medium: 60px;
$modal-right: 80px;
$modal-bottom: 140px;
$modal-small-bottom: 130px;

$desktop-width: 100%;
$mobile-width: 100vw;
$desktop-header-height: 60px;
$mobile-header-height: 42px;
$mobile-footer-height: 70px;
$desktop-footer-height-medium: 90px;
$desktop-footer-height: 105px;

// COLORS

$color-primary: #eeba59;
$color-action: #00b0ff;
$color-error: #e51b72;
$color-cool: #b7bec3;
$color-light: #e3e7ea;
$color-font: #284e7b;
$color-pink: #bd7eee;
$color-pink-dark: #bf3efb;
$color-background: #f7f7f7;
$color-purple: #a637fc;

// DROP SHADOW

%drop-shadow-4 {
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

%drop-shadow-5 {
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

%drop-shadow-material {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

%drop-shadow-material-raised {
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

// TEXT SHADOW

%text-shadow {
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

// ANIMATIONS

%boardFadeInAnimation {
	animation: board-fade-in 1.4s;
}

@keyframes board-fade-in {
	0% {
		opacity: 0;
		transform: scale(0);
	}

	50% {
		opacity: 0;
		transform: scale(0.7);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

%boardFadeOutAnimation {
	animation: board-fade-out 1.4s;
}

@keyframes board-fade-out {
	0% {
		opacity: 1;
		transform: scale(1);
	}

	50% {
		opacity: 0;
		transform: scale(0.7);
	}

	100% {
		opacity: 0;
		transform: scale(0);
	}
}

%boardSlideRight {
	animation: board-slide-right 1s;
}

%boardSlideRightInitial {
	animation: invisible 3s, fade-in 3s, board-slide-right 1s;
}

@keyframes board-slide-right {
	0% {
		transform: translateX(var(--r, -100%));
	}

	50% {
		transform: translateX(var(--r, -100%));
	}

	100% {
		transform: translateX(0);
	}
}

%boardSlideLeft {
	animation: board-slide-left 1s;
}

%boardSlideLeftInitial {
	animation: invisible 3s, fade-in 3s, board-slide-left 1s;
}

@keyframes board-slide-left {
	0% {
		transform: translateX(100%);
	}

	50% {
		transform: translateX(100%);
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes invisible {
	from {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

%fadeInAnimation {
	animation: fade-in ease-in 1s;
}

@keyframes fade-in {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

%slidingTextAnimation {
	animation: 8s slide-text linear infinite;
}

@keyframes slide-text {
	from {
		left: 380px;
	}

	to {
		left: -380px;
	}
}

%fadeAndGrowAnimation {
	animation: fade-and-grow 0.2s;
}

@keyframes fade-and-grow {
	from {
		opacity: 0;
		height: 0;
	}

	to {
		opacity: 1;
		height: 20px;
	}
}

%slideUpAnimation {
	animation: slide-up 0.1s ease-in-out;
}

@keyframes slide-up {
	from {
		height: 0;
		opacity: 0;
	}

	to {
		height: 100%;
		opacity: 1;
	}
}

%uploadFrameAnimation {
	animation: border-dance 4s infinite linear;
}

@keyframes border-dance {
	0% {
		background-position: left top, right top, right bottom, left bottom;
	}

	100% {
		background-position: right top, right bottom, left bottom, left top;
	}
}

@mixin btn-done {
	color: $color-action;
	color: $color-pink-dark;
	float: right;
	border: none;
	outline: none;
	font-size: 20pt;
	font-weight: bold;
	height: 50px;
	padding: 0;
	background: #fff;
	cursor: pointer;
}

%borderDance {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(90deg, #8193B7 50%, transparent 50%), linear-gradient(0deg, #8193B7 50%, transparent 50%),
		linear-gradient(90deg, #8193B7 50%, transparent 50%), linear-gradient(0deg, #8193B7 50%, transparent 50%);
	background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
	background-size: 22px 3px, 3px 22px, 22px 3px, 3px 22px;
	background-position: left top, right top, left bottom, left top;
}

// border: 3px solid;

// border-image-source: linear-gradient(135.23deg, #F3F4F6 -53.9%, #8193B7 135.27%);

