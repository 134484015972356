@import '../../../utils/variables';

#sb-CheckoutInfo {
  position: fixed;
  margin: 0;
  right: 0;
  bottom: 0px;
  z-index: 50;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #f7f7fa;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
  border-radius: 4px 4px 0 0;
  box-shadow: 0px 1px 59px rgba(35, 33, 66, 0.1);
  max-height: 100%;

  @include mq(phone-only) {
    box-shadow: none;
  }

  &.withInfo {
    @include mq(phone-only) {
      padding-bottom: 0px;
    }
  }

  &-overlay {
    background: black;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 10;
    filter: opacity(0.5);
  }

  &-overlay2 {
    background: black;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 21;
    filter: opacity(0.2);
  }

  &-overlay3 {
    top: 0;
    width: 100%;
    z-index: 21;
    height: 100%;
    position: fixed;
    background: black;
    filter: opacity(0.2);
  }

  .payment-menu {
    position: absolute;
    z-index: 20;
    top: 45px;
    left: -0px;
    width: calc(100% - 0px);
    display: none;
    background-color: #f7f7fa;
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 23px 65px rgba(35, 33, 66, 0.07);

    &-show {
      display: block;
    }

    > div {
      height: 45px;
      display: flex;

      span {
        height: 22px;
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    > div:hover {
      background: #ebebf4;
    }
  }

  @media only screen and (min-width: 990px) {
    left: 50%;
    position: fixed;
    transform: translate(-50%);
  }

  @include mq(small-desktop-up) {
    padding-top: 0;
    width: 450px;
  }

  @media only screen and (min-width: 1440px) and (min-height: 701px) {
    right: $modal-right;
  }

  @include mq(medium-desktop-up) {
    width: $footer-button-width;
  }
}

// CLASSES

.sb-CheckoutInfo {
  &-header {
    background-color: white;
  }

  &__upsell {
    .sb-Checkbox {
      margin-right: 8px;
    }

    &-learn {
      color: #acacac;
      margin-left: 10px;
      font-size: 15px;
      font-weight: normal;
      text-decoration: underline;
      cursor: pointer;
      line-height: 20px;
    }
    padding: 10px 0 0 0;
    color: #00b0ff;
    display: flex;
    align-items: center;
    &-price {
      align-self: flex-end;
      position: absolute;
      right: 15px;
    }
    span {
      &:nth-of-type(1) {
        align-self: flex-start;
      }
    }
  }

  &__messaging {
    height: 20px;
    display: flex;
    justify-content: space-evenly;
    vertical-align: center;

    svg {
      height: 20px;
      width: 60px;
      margin-right: 15px;
    }
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 0 0;
    margin-top: -5px;
    margin-bottom: -5px;

    span {
      margin-left: -10px;
    }

    @media only screen and (max-width: 440px) {
      svg {
        margin-right: 0;
        width: 65px;
      }
      span {
        white-space: nowrap;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        margin-left: 0;
      }
    }
  }

  &-title-closebtn {
    text-align: end;
    margin: 0 20px;
    cursor: pointer;
    color: #e5e5e5;
    font-size: $checkout-close-font;
    font-size: 16px;
    font-weight: 700;
  }

  &-title {
    grid-column: 2 / 3;
    height: $checkout-title-height;
    line-height: $checkout-title-height;
    font-size: $checkout-title-font;
    align-self: center;
    text-align: center;
    font-weight: 700;
    color: $color-font;
    letter-spacing: 0.7px;

    @media only screen and (max-width: 989px) {
      display: none;
    }
  }

  &-button,
  &-button-inactive {
    position: relative;
    width: 100%;
    height: 45px;
    border-radius: 4px;
    margin-top: 15px;
    padding: 0 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    outline: none;
    border: none;
    font-size: 16px;
    font-weight: 600;
    color: $color-font;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 23px 65px rgba(35, 33, 66, 0.07);
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 23px 65px rgba(35, 33, 66, 0.07);
    }

    > img,
    > div > img {
      width: 20pt;
    }

    > span {
      line-height: 1.5;
      font-weight: 600;
      margin-left: 18px;
    }
    > div > span {
      line-height: 1.5;
      font-weight: 600;
      margin-left: 18px;
    }

    .expand {
      flex-grow: 1;
      text-align: end;

      img {
        position: relative;
        height: 9px;
      }
    }

    div.payment {
      display: flex;
      width: 100%;
      text-align: center;

      :nth-child(2) {
      }
    }

    &-textDone {
      color: rgb(166, 55, 252);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      display: block;
      text-align: center;
      margin-left: -16px;
      margin-left: -12px;

      @include mq(phone-only) {
        margin-left: -8px;
      }
    }
  }

  &-button-expanded {
    border-radius: 8px 8px 0 0;

    & .expand {
      img {
        transform: rotate(180deg);
      }
    }
  }

  &-button-inactive {
    background: rgb(172, 172, 172) !important;
    cursor: not-allowed;
    color: white !important;

    span {
      color: white !important;
      opacity: 0.8;
    }
  }

  &-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 90px;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

    @media only screen and (max-width: 989px) {
      box-shadow: none;
      left: 0;
      z-index: 0;
      width: 100%;
      position: relative;
    }

    &-btnInactive {
      background: rgb(172, 172, 172) !important;
    }

    > div {
      width: 300px;
      position: relative;
      top: 50px;
    }

    > button {
      position: absolute;
      right: 20px;
      width: 380px;
      height: 50px;
      border-radius: 0.15em;
      border: none;
      font-size: 20pt;
      font-weight: 700;
      color: #fff;
      background: $color-action;
      cursor: pointer;
      @extend %drop-shadow-material;

      @media only screen and (max-width: 989px) {
        position: relative;
        right: 0;
        width: 90%;
        height: 50px;
      }

      @include mq(phone-only) {
        width: 300px;
        height: 50px;
      }
    }
  }

  &-paypal {
    width: 100%;
    height: 100%;
    background-color: #0065ae;
    border: none;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      transform: translate(0px, 2px);
    }
  }

  &-applepay {
    background: url('../../../assets/images/menu/appleNew.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &-discount {
    color: $color-action !important;
  }

  &-infoMessage {
    width: 100%;
    padding: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14pt;
    color: $color-pink;

    @media only screen and (max-width: 360px) {
      font-size: 12pt;
    }

    > img {
      height: 30px;
      margin-right: 10px;
      position: relative;
      bottom: 0;

      @media only screen and (max-width: 360px) {
        height: 28px;
      }
    }
  }

  &-checkoutInfoWrapper {
    width: 100%;
    height: fit-content;
    font-size: 17px;

    &-saveInfo {
      margin: 0 -15px;
      padding: 10px 0;
      text-align: center;
      background-color: #eeba59;
      color: #fff;
    }

    > h3 {
      margin-top: 15px;
      margin-bottom: 10px;
      color: $color-font;
      letter-spacing: 0.6px;
      font-size: 20px;
      font-weight: 700;
    }

    &-checkoutInfo {
      width: 100%;
      height: fit-content;

      &-discountName {
        font-weight: bold;
        margin-bottom: 20px;

        span#coupon {
          background-image: linear-gradient(
            91.72deg,
            #a637fc -2.79%,
            #f34ef8 105.62%
          );
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          text-transform: uppercase;
          font-weight: 700;
        }

        span#remove {
          color: $color-font;
          margin-left: 10px;
          font-size: 14px;
          font-weight: 300;
          text-decoration: underline;
          cursor: pointer;
          opacity: 0.5;
        }

        span:last-child:not(#remove) {
          float: right;
          font-weight: bold;
        }
      }

      p {
        color: $color-font;
        margin: 6px 0;
        display: flex;
        // justify-content: space-between;
        font-weight: 600;
        letter-spacing: 0.5px;

        &:first-child {
          > span:first-child {
            font-size: 14px;
            font-weight: 300;
          }
        }

        > span:last-child {
          float: right;
          font-weight: bold;
        }
      }

      &-fullPrice {
        position: relative;
        margin-left: auto;
        display: inline-block;

        &::before {
          position: absolute;
          content: '';
          left: 0;
          top: 50%;
          right: 0;
          border-top: 1px solid;
          border-color: inherit;
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
      }

      &-total {
        font-weight: bold;
        color: $color-font !important;
        padding: 10px 0;
        margin-top: 20px 0 !important;
        border-top: 1px dashed rgba(40, 78, 123, 0.5);
        border-bottom: 1px dashed rgba(40, 78, 123, 0.5);

        > span {
          float: right;
          background-image: linear-gradient(
            91.72deg,
            #a637fc -2.79%,
            #f34ef8 105.62%
          );
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          text-transform: uppercase;
        }
      }
    }
  }

  &-slideUp {
    // bottom: 0 !important;
  }

  &-slideDown {
    bottom: -1900px !important;
  }

  &-fadeIn {
    opacity: 1 !important;
    visibility: visible !important;
  }

  &-fadeOut {
    opacity: 0 !important;
    visibility: hidden !important;
  }

  @include mq(medium-desktop-up) {
    &-title {
      // width: $footer-button-width;
    }

    &-button {
      & .expand {
        img {
          width: 30px;
        }
      }
    }

    &-button-expanded {
    }

    &-checkoutInfoWrapper {
      font-size: 18px;

      &-checkoutInfo {
        &-total {
        }
      }
    }
  }
}

@keyframes tip {
  0% {
    bottom: 0;
  }

  50% {
    bottom: 10px;
  }

  100% {
    bottom: 0;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

#sb-BoardMenu {
  z-index: 9999;
}

klarna-placement > div::part(osm-container) {
  border: none;
}

.sk-fading-circle {
  width: 24px;
  height: 24px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #00b341;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

// Fix for paypal wrapper bigger height than set in component
#payment-wrapper > div {
  height: 55px !important;
}
