@import '../../../utils/variables';

.sb-OrderCompleted {
	position: fixed;
	width: 100%;
	height: calc(100vh - #{$mobile-header-height} - #{$mobile-footer-height});
	padding-top: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center; 
	padding-bottom: 150px;
	background-color: $color-background;

	@media only screen and (max-height: 650px) and (min-width: 599px) {
		overflow-y: scroll;
		width: 100%;
		display: flex;
		flex-direction: column;
		padding-bottom: 150px;
	}

	@include mq(phone-only) {
		position: fixed;
	}

	&-imageWrapper {
		width: 60px;
		height: 60px;
		position: relative;
		top: 30px;
		margin-left: calc(50% - 22px);

		@include mq(phone-only) {
			width: 50px;
			height: 50px;
			top: 42px;
			margin-left: calc(50% - 30px);
		}

		>img {
			width: 100%;
		}
	}

	&-storylab {
		text-align: center;
		font-size: 17px;
		display: inline-block;
		width: calc(100% - 50px);
		margin: 0 25px;
		color: #6f6f6e;

		span {
			display: inline-block;
			color: #867f8b;
			font-weight: 500;
		}

		@include mq(phone-only) {
			font-size: 15px;
		}
	}

	&-message {
		-webkit-box-shadow: 2px 3px 12px -1px rgba(168, 168, 168, 1);
		-moz-box-shadow: 2px 3px 12px -1px rgba(168, 168, 168, 1);
		box-shadow: 2px 3px 12px -1px rgba(168, 168, 168, 1);
		height: 150px;
		top: 0;
		display: flex;
		justify-content: space-evenly;
		flex-direction: column;
		margin: 0 auto;
		width: 60%;
		position: relative;
		border-radius: 0.4em;
		padding: 10px;
		font-size: 15px;
		border: 1px solid #f7f7f7;
		background-color: white;


		@include mq(phone-only) {
			min-height: 100px;
			width: 100%;
			max-width: 325px;
			margin: 0 auto;
			font-size: 15px;
			top: 20px;
		}

		&-user {
			img {
				margin-right: 15px;
				width: 30px;
				height: 30px;

				@include mq(phone-only) {
					width: 25px;
					height: 25px;

				}
			}

			font-size: 20px;
			align-items: center;
			display: flex;
			justify-content: center;
			font-weight: 500;
			font-weight: 700;
			margin-bottom: 10px;

			@include mq(phone-only) {
				margin-bottom: 0;
			}
		}

		p {
			color: $color-purple;
			text-align: center;
			margin: 0;
			font-size: 25px;
			font-weight: 700; 

			@include mq(phone-only) {
				font-size: 16px;
			}
		}

		span {
			text-align: center;
			color: #6f6f6e;
			display: block;
			margin-bottom: 10px;

			@include mq(phone-only) {
				margin-bottom: 0;
			}
		}

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 56%;
			width: 0;
			height: 0;
			border: 20px solid transparent;
			border-top-color: #fffefd;
			border-bottom: 0;
			border-left: 0;
			margin-left: -10px;
			margin-bottom: -20px;
		}
	}

	&-orderId {
		position: relative;
		margin: 0 auto;
		// color: white;
		top: -20px;
	}

	&-orderStatus {
		margin-bottom: 10px;
		margin-top: 40px;
		text-align: center;
		color: $color-purple;
		line-height: 2.5;
		font-size: 25pt;
		font-weight: 700; 



		@include mq(phone-only) {
			margin-top: 48px;
			margin-bottom: 0;
			font-size: 27px;
			line-height: 40px;
			letter-spacing: 0.11px;
		}
	}

	&-loaderWrapper {
		width: 400px;
		height: fit-content;
		margin: 0 auto;
		border-radius: 4px;

		>h3 {
			padding-top: 40px;
			color: $color-primary;
			text-align: center;
		}

		>p {
			color: $color-primary;
			text-align: center;

			&:last-of-type {
				margin-top: 20px;
				font-size: 24px;
				color: rgb(255, 0, 0);

				@media only screen and (max-width: 320px) {
					font-size: 20px;
				}
			}
		}

		@media only screen and (max-width: 989px) {
			width: 80%;
		}
	}

	&-footer {
		position: fixed;
		bottom: 0;
		width: 100%;

		@media only screen and (max-width: 989px) {
			width: 100%;
		}
	}

	&-delivery {
		width: 100%;
		height: fit-content;
		display: flex;
		justify-content: center;
		margin-top: 20px;

		@include mq(phone-only) {
			margin-top: 0;
		}

		>p {
			margin: 5px 0;
			padding-left: 20px;
			color: #867f8b;
			text-align: left;
			font-weight: bold;
			font-size: 17px; 

			@include mq(phone-only) {
				text-align: center;
				padding-left: 0;
				font-size: 17px;
			}

			>img {
				width: 30px;

				@include mq(phone-only) {
					padding-left: 0;
				}
			}

			>span {
				padding-left: 10px;
				position: relative;
				top: -7px;
			}
		}
	}

	&-footerBtnWrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		background: white;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
		height: $mobile-footer-height;
		
		>a {
			margin: 10px 0;
			padding: 7px 14px;
			background-color: $color-action;
			border-radius: 4px;
			text-decoration: none;
			font-size: 30pt;
			font-weight: 700;
			color: #fff;
			border: none;
			outline: none;
			width: 350px;
			
			&:disabled {
				background: rgb(172, 172, 172) !important;
				cursor: not-allowed;
			}
			
			@extend %drop-shadow-material;
			
			&:hover {
				@extend %drop-shadow-material-raised;
			}
			
			@media only screen and (max-width: 1440px) {
				font-size: 24pt;
			}
		}
		@media only screen and (min-width: 990px) {
			height: $desktop-footer-height-medium;
		}
	
		@include mq(small-desktop-up) {
			height: $desktop-footer-height;
		}
	}

	@media only screen and (min-width: 990px) and (max-height: 700px) {
		&-delivery {
			margin-top: 0;
		}

		&-orderStatus {
			margin-top: 0;
			margin-bottom: -20px;
		}
	}

	@media only screen and (min-width: 700px) {
		height: calc(100vh - #{$desktop-header-height} - #{$mobile-footer-height});
		padding-top: 0;
		padding-bottom: 0;
	}

	@media only screen and (min-width: 990px) and (min-height: 700px) {
		height: calc(100vh - #{$desktop-header-height} - #{$desktop-footer-height-medium});
	}

	@media only screen and (min-width: 1200px) and (min-height: 700px) {
		height: calc(100vh - #{$desktop-header-height} - #{$desktop-footer-height});
	}
}

.upper::first-letter {
	text-transform: uppercase;
}

.upper {
	margin-left: 5px !important;
}
