.frame-select {
  bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &-slideUp {
    bottom: 10px;
    transition: all 0.2s ease-in-out;
  }

  &-slideDown {
    bottom: -900px;
    transition: all 0.2s ease-in-out;
  }

  &.hide {
    display: none;
  }

  .frames-container {
    display: flex;
    transition: all 0.2s ease-in-out;

    .single-frame {
      display: flex;
      flex-direction: column;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      &.hide {
        display: none;
      }
      &.selected {
        border-radius: 4px;

        p,
        span {
          color: #a637fc;
        }
      }

      p {
        font-size: 16px;
        font-weight: bold;
        color: #284e7b;
        margin: 0;
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
  }

  @media screen and (max-width: 470px) {
    .frames-container {
      width: 100%;
      padding: 8px 0;
      .single-frame {
        // margin: 0 4px;
        p {
          font-size: 12px;
        }
      }
    }
  }
}

.beforePrice {
  color: #3a3a3a;
  opacity: 0.5;
  position: relative;
  padding: 2px;
  margin-left: 10px;
  display: inline-block;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
