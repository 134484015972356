@import '../../../utils/variables';

.sb-Pricing {
	position: relative;
	left: -24px;
	width: 300px;
	height: 130px;
	display: flex;
	flex-direction: column;
	padding: 0;
	margin: 0 auto;

	.text-orange {
		color: #eeba59;
		font-size: 27px;
	}

	.text-grey {
		color: #9a9999;
	}

	&-bundle-mobile {
		position: relative;
		height: 60px;
		margin: 0 20px;
		margin-right: 10px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		border-bottom: solid 1px lightgray;

		& .sb-Pricing-bundle-left {
			position: absolute;
			left: -20px;
			color: #acacac;

			.strikethrough {
				font-size: 22px;
				color: #d7d7d7;

				&::before {
					transform: none;
				}
			}

			.text-orange:first-child {
				margin-right: 5px;
			}
		}

		.text-orange {
			font-weight: bold;
		}

		.text-small {
			font-size: 15px;
			font-weight: 400;
		}

		.text-medium {
			font-size: 20px;
		}

		.sb-Pricing__badge {
			top: 2px;
			right: -42px;

			img {
				width: 55px;
				height: 55px;
			}

			.sb-Pricing__badge_save {
				font-size: 32px;

				.saveAmount {
					font-size: 22px;
					&_medium {
						font-size: 16px;
					}
				}

				> span:first-child {
					&.text-small {
						font-size: 10px;
						font-weight: 900;
						left: 24%;
					}
				}

				.saveUnit {
					font-size: 12px;
				}
			}
		}
	}

	&.no-animation {
		left: 0;
		height: 320px;
		width: 93.6vw;
		margin: 0 auto;
		border-radius: 10px;

		& .free-shipping {
			padding: 0;
			margin-top: 16px;
		}

		.widget-container {
			height: 360px;
		}

		@media only screen and (min-width: 700px) {
			width: 600px;
		}
	}

	& .free-shipping {
		padding: 18px;
		padding-bottom: 0;
		font-size: 18px;
		font-weight: 300;
		line-height: 0.83;
		letter-spacing: 0.07px;
		color: #9a9999;
		text-align: center;

		svg {
			width: 19px;
			height: 19px;
			margin-right: 12px;

			path {
				fill: #9a9999;
			}
		}

		span {
			position: relative;
			top: -3px;
		}
	}

	.widget-container {
		position: relative;
		z-index: 21;
		height: 96px;
		background-color: white;
		border-radius: 10px;
		// box-shadow: 0 0 8.5px 0 rgba(0, 0, 0, 0.3);

		&.bundle-mobile-container {
			height: 270px;
			width: 93.6vw;
			margin: 0 auto;
			padding: 12px;
			padding-right: 45px;
			box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
			margin-bottom: 5px;

			h3 {
				margin: 0;
				text-align: end;
			}

			.sb-Pricing__footnote {
				text-align: center;
				margin: 10px 0;
				font-weight: 300;
				color: #9a9999;
			}

			@media only screen and (min-width: 700px) {
				width: 600px;

				h3 {
					position: relative;
					left: 24px;
					text-align: start;
				}
			}
		}
	}

	&__widget {
		opacity: 1;
		position: absolute;
		top: 0;
		width: 300px;
		box-shadow: -3px 7px 21px rgba(53, 27, 73, 0.1);
		border-radius: 10px;

		&__no-animation {
			visibility: visible;
			position: relative;
			margin-bottom: 20px;
		}

		&__show {
			opacity: 1;
		}

		&.widgetItem {
			&-enter {
				opacity: 0;
				transition: opacity 900ms;
			}

			&-enter-active {
				opacity: 1;
			}

			&-exit {
				opacity: 1;
			}

			&-exit-active {
				opacity: 0;
				transition: opacity 200ms;
			}

			&-exit-done {
				visibility: hidden;
			}
		}
	}

	&__badge {
		position: absolute;
		right: -65px;
		top: -8px;
		z-index: 12;

		img {
			width: 115px;
			height: 115px;
		}

		&_save {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			margin: 0;
			// transform: translate(-60%, -50%);
			color: white;
			font-size: 48px;
			font-weight: 900;

			span.saveAmount {
				position: absolute;
				top: 32%;
				left: 20%;

				&_medium {
					top: 37%;
					font-size: 35px;
				}

				&.saveItem {
					&-enter {
						left: 34%;
						z-index: 15;
					}

					&-enter-active {
						left: 20%;
						z-index: 15;
						transition: left 900ms, z-index 900ms;
					}

					&-exit {
						left: 20%;
						opacity: 1;
					}

					&-exit-active {
						left: -200px;
						opacity: 0;
						transition: opacity 900ms, left 900ms;
					}

					&-exit-done {
						left: -200px;
						z-index: 0;
					}
				}
			}

			> span:first-child {
				position: absolute;
				top: 15%;
				left: 22%;
				font-size: 25px;
				font-weight: 700;
			}

			span.saveUnit {
				position: absolute;
				top: 41%;
				right: 9%;
				font-size: 20px;
				font-weight: 700;
				vertical-align: top;
			}

			&.dollar {
				span.saveAmount {
					left: 25%;
				}
				span.saveUnit {
					left: 12%;
				}
			}

			&.percent {
				span.saveAmount {
					left: 20%;
				}
				span.saveAmount_medium {
					left: 13%;
				}
				span.saveUnit {
					// left: 12%;
					font-size: 21px;
				}
			}
		}
	}

	&__wrapper {
		position: relative;
		width: 312px;
		height: 96px;
		padding: 12px 16px;
		padding-right: 80px;
		display: flex;
		align-items: center;
		background-color: white;
		border-radius: 10px;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		align-items: center;

		@media screen and (min-width: 1200px) {
			padding-right: 60px;
		}

		&-bundle {
			.sb-Pricing__wrapper__item {
				opacity: 0;
			}
		}

		& .wrapperItem {
			&-enter {
				opacity: 0;
				left: 50px;
				transition: opacity 900ms;
			}

			&-enter-active {
				opacity: 1;
				z-index: 10;
				left: 0;
				transition: left 1200ms, opacity 1200ms;
			}

			&-enter-done {
				left: 0;
				opacity: 1;
			}

			&-done {
				left: -100px;
			}

			&-exit {
				opacity: 1;
				left: 0;
			}

			&-exit-active {
				opacity: 0;
				left: -400px;
				transition: opacity 50ms, left 5000ms;
			}

			&-exit-done {
				opacity: 0;
				left: -400px;
			}
		}

		&:last-child {
			height: 40%;
			display: flex;
			flex-direction: column;
			margin: 0 auto;
			align-items: center;
		}

		&__item {
			position: relative;
			margin: 2px 0;
			color: #adadad;

			img {
				height: 70%;
				margin: 5px;

				@include mq(phone-only) {
					height: 25px;
				}
			}

			h3 {
				line-height: 31px;
				color: $color-pink;
				margin: 0;
				display: flex;
				align-items: center;
				letter-spacing: 0.5px;
			}
		}

		@include mq(phone-only) {
			margin-right: 10px;
		}
	}

	&__toptitle {
		font-size: 18px;
		color: #9a9999;

		> span {
			font-size: 26px;
			font-weight: 600;
		}
	}

	&__title {
		font-size: 100%;
		span {
			color: #f6dcac;
		}
	}

	&__subtitle {
		color: #9a9999;
		font-size: 17px;
	}

	&__footnote {
		font-size: 17px;
		font-weight: 300;
		color: #9a9999;

		span {
			font-weight: 500;
		}
	}

	.strikethrough {
		position: relative;
		color: #b6bdc2;
		font-size: 110%;

		&::before {
			position: absolute;
			content: '';
			left: 0;
			top: 50%;
			right: 0;
			border-top: 1px solid;
			transform: rotate(-15deg);
		}
	}

	&__each {
		position: absolute;
		top: 74px;
		width: 400px;
		padding: 0 60px 25px;
		color: #9a9999;
		font-size: 17px;
		line-height: 0.82;
		letter-spacing: 0.07px;
		text-align: left;
		font-weight: 300;
	}
	.text-pink {
		color: $color-pink;
		font-weight: 600;
	}

	@media only screen and (min-width: 990px) {
		flex-direction: row;
		align-items: center;
		padding: 0;
		margin-top: 0;
		margin-left: 0;
		left: 20px;
		width: 290px;
		height: 110px;

		&__widget {
			width: 290px;
		}

		&__wrapper {
			height: 100px;
			position: relative;

			// &__item {
			// 	h3 {
			// 		font-size: 26px;
			// 	}
			// }
		}

		& .free-shipping {
			display: none;

			svg {
				width: 19px;
				height: 19px;
				margin-right: 12px;
			}
		}

		&__badge {
			right: -80px;
			top: -9px;

			img {
				height: 120px;
				width: 120px;
			}

			&_save {
				font-size: 47px;

				// > span:nth-child(2) {
				// }

				> span:first-child {
					font-size: 25px;
					font-weight: 700;
				}

				span:last-child {
					font-size: 25px;
				}
			}
		}

		&__each {
			width: 400px;
			top: 125px;
			font-size: 20px;
		}
	}

	@media only screen and (min-width: 1200px) and (min-height: 701px) {
		left: 0;
		width: 350px;
		height: 150px;

		&__widget {
			width: 300px;
		}

		&__each {
			top: 130px;
			font-size: 24px;
		}
	}

	&-mobileOnly {
		@media only screen and (min-width: 990px) {
			display: none;
		}
	}
}
